import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store";

Vue.use(VueRouter);

const routes = [{
  path: "/",
  name: "EventPicker",
  component: () =>
    import("@/views/EventPicker.vue")
},
{
  path: "/admin",
  name: "admin",
  component: () =>
    import("@/views/Admin.vue"),
  props: true,
  meta: {
    requiresAdmin: true,
    needsFullEvents: true
  }
},
{
  path: "/admin/:eventId",
  component: () =>
    import("@/views/Admin.vue"),
  props: true,
  meta: {
    requiresAdmin: true,
    needsFullEvents: true
  }
},
{
  path: "/analytics/:eventId?",
  name: "analytics",
  component: () =>
    import("@/views/AnalyticsDownload.vue"),
  props: true,
  meta: {
    requiresAdmin: true,
    title: "Analytics"
  }
},
{
  path: "/chat-admin/:eventId?",
  name: "chatadmin",
  component: () =>
    import("@/views/ChatAdmin.vue"),
  props: true,
  meta: {
    requiresAdmin: true,
    title: "Chat Admin"
  }
},
{
  path: "/global-login",
  name: "global-login",
  component: () => import("@/views/LogIn.vue")
},
{
  path: "/:meetingCode",
  redirect: "/:meetingCode/schedule"
},
{
  path: "/:meetingCode/login",
  name: "Login",
  component: () =>
    import("@/views/LogIn.vue")
},
{
  path: "/:meetingCode/logout",
  name: "Logout",
  component: () =>
    import("@/views/LogOut.vue")
},
{
  path: "/:meetingCode/refresh",
  name: "RefreshData",
  component: () =>
    import("@/views/Refresh.vue"),
  meta: {
    requiresAuth: true,
    claimOnly: true,
    title: "Loading"
  }
},
{
  path: "/:meetingCode/not-attending",
  name: "NotAttending",
  component: () =>
    import("@/views/NotAttending.vue"),
  meta: {
    requiresAuth: true,
    claimOnly: true
  }
},
{
  path: "/:meetingCode/event-archived",
  name: "EventArchived",
  component: () => 
    import("@/views/EventArchived.vue"),
  meta: {
    requiresAuth: true,
    claimOnly: true
  }
},
{
  path: "/:meetingCode/not-available-to-reg-type",
  name: "NotAvailableToRegType",
  component: () =>
    import("@/views/NotAttending.vue"),
  meta: {
    requiresAuth: true,
    claimOnly: true
  },
  props: {
    regType: true
  }
},
{
  path: "/:meetingCode/schedule",
  name: "Schedule",
  component: () =>
    import("@/views/Schedule.vue"),
  meta: {
    requiresAttending: true
  }
},
{
  path: "/:meetingCode/materials",
  name: "Materials",
  component: () =>
    import("@/views/Schedule.vue"),
  meta: {
    requiresAttending: true
  },
  props: {
    materialsOnly: true
  }
},
{
  path: "/:meetingCode/analytics",
  name: "MeetingAnalytics",
  component: () => import("@/views/AnalyticsDownload.vue"),
  props: {
    singleEventMode: true
  },
  meta: {
    requiresAttending: true,
    claimOnly: true,
    title: "Analytics"
  }
},
{
  path: "/:meetingCode/chat-admin",
  name: "MeetingChatManage",
  component: () => import("@/views/ChatAdmin.vue"),
  props: {
    singleEventMode: true
  },
  meta: {
    requiresAttending: true,
    requiresAdmin: true,
    claimOnly: true,
    title: "Chat Admin"
  }
},
{
  path: "/:meetingCode/chat-admin/:sessionId?",
  name: "SessionChatManage",
  component: () => import("@/views/ChatAdmin.vue"),
  props: {
    singleEventMode: true,
    singleSessionMode: true
  },
  meta: {
    requiresAttending: true,
    requiresAdmin: true,
    claimOnly: true,
    title: "Chat Admin"
  }
},
{
  path: "/:meetingCode/sponsors",
  name: "Sponsors",
  component: () =>
    import("@/views/Sponsors.vue"),
  meta: {
    requiresAttending: true,
    title: "Sponsors"
  }
},
{
  path: "/:meetingCode/sponsor",
  name: "Sponsor",
  component: () =>
    import("@/views/Sponsors.vue"),
  meta: {
    requiresAttending: true,
    title: "Sponsor"
  }
},
{
  path: "/:meetingCode/supporters",
  name: "Supporters",
  component: () =>
    import("@/views/Supporters.vue"),
  meta: {
    requiresAttending: true,
    title: "Supporters"
  }
},
{
  path: "/:meetingCode/on-demand",
  name: "OnDemand",
  component: () =>
    import("@/views/OnDemand.vue"),
  meta: {
    requiresAttending: true,
    title: "On-Demand"
  }
},
{
  path: "/:meetingCode/faculty/:facultyId?",
  name: "Faculty",
  component: () => import("@/views/Faculty.vue"),
  props: true,
  meta: {
    requiresAttending: true,
    claimOnly: true,
    title: "Faculty"
  }
},
{
  path: "/:meetingCode/claim",
  name: "Claim",
  component: () =>
    import("@/views/Claim.vue"),
  meta: {
    requiresAttending: true,
    claimOnly: true,
    title: "Credit Claim"
  }
},
{
  path: "/:meetingCode/survey",
  name: "Survey",
  component: () =>
    import("@/views/Survey.vue"),
  meta: {
    requiresAttending: true,
    claimOnly: true,
    title: "Survey"
  }
},
{
  path: "/:meetingCode/survey-submitted",
  name: "SurveySubmitted",
  component: () =>
    import("@/views/SurveySubmitted.vue"),
  meta: {
    requiresAttending: true,
    claimOnly: true,
    title: "Survey Submitted"
  }
},
{
  path: "/:meetingCode/survey-results",
  name: "SurveyResults",
  component: () =>
    import("@/views/SurveyResults.vue"),
  meta: {
    requiresAttending: true,
    claimOnly: true,
    title: "Survey Results"
  }
},
{
  path: "/:meetingCode/challenge",
  name: "Challenge",
  component: () =>
    import("@/views/Challenge.vue"),
  meta: {
    requiresAttending: true,
    title: "Challenge"
  }
},
{
  path: "/:meetingCode/discussions/:id?",
  name: "Discussions",
  component: () =>
    import("@/views/Discussions.vue"),
  meta: {
    requiresAttending: true,
    title: "Discussions"
  },
  props: true
},
{
  path: "/:meetingCode/session/:id?/:mode?",
  name: "Scheduled-Event",
  component: () =>
    import("@/views/ScheduledEvent.vue"),
  meta: {
    requiresAttending: true,
  },
  props: true
},
{
  path: "/:meetingCode/abstracts",
  name: "Abstracts",
  component: () => import("@/views/Abstracts.vue"),
  meta: {
    requiresAttending: true,
    title: "Abstracts"
  }
},
{
  path: "/:meetingCode/sessions",
  name: "Sessions",
  component: () => import("@/views/Sessions.vue"),
  meta: {
    requiresAttending: true,
    title: "Sessions"
  }
},
{
  path: "/:meetingCode/watchlist",
  name: "Watchlist",
  component: () => import("@/views/Watchlist.vue"),
  meta: {
    requiresAttending: true,
    title: "Watchlist"
  }
}
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, _, savedPosition) {
    if (to.meta.perserveScroll === false || !savedPosition) {
      return { x: 0, y: 0 };
    }
    return savedPosition;
  }
});

let scheduleScrollPositionY = 0;

//Route guard
router.beforeEach(async (to, from, next) => {

  await store.dispatch("getListings");

  trackActivity(to);

  store.commit("setMeetingCode", to.params.meetingCode);

  document.body.className = to.params.meetingCode;

  //Route for specific event and event not found
  //Redirect back to event selection (root)
  if (to.params.meetingCode && !store.getters.meetingData) {
    next({ path: "/" });
    return;
  }

  //Set browser title
  if (store.getters.meetingData) {
    const shortName = store.getters.meetingData.shortName;

    if (to.matched.length && to.matched[0].meta.title) {
      document.title = `${to.matched[0].meta.title} | ${shortName}`;
    } else {
      document.title = shortName;
    }

  } else {
    document.title = "Select Meeting";
  }

  if (!store.state.startingRoute)
    store.commit("setStartingRoute", to);

  //Auth pass-thru
  if (!store.state.authToken && to.query.bearer) {
    store.commit("setAuthToken", to.query.bearer);
  }

  //Route requirements
  const requiresAuth = to.matched?.some(record => record.meta.requiresAuth);
  const requiresAttending = to.matched?.some(record => record.meta.requiresAttending);
  const requiresAdmin = to.matched?.some(record => record.meta.requiresAdmin);
  const needsFullEvents = to.matched?.some(record => record.meta.needsFullEvents);

  //Route needs
  const needsAuth = !store.state.authToken && (requiresAuth || requiresAttending);
  const needsRefresh = requiresAttending && !store.getters.isAttending;
  const needsAdminAuth = !store.state.authToken && requiresAdmin;

  if (needsAdminAuth) {
    router.push({ path: `/global-login` }).catch(() => { });
    return;
  }

  if (requiresAdmin) {
    const personData = await store.dispatch("getAdminStatus");

    if (!personData.isAdmin) {
      next({ name: 'EventPicker', replace: true });
      return;
    }

    if (needsFullEvents)
      await store.dispatch("getAdminListing");

    if (needsRefresh) {
      router.push({ path: `/${to.params.meetingCode}/refresh`, query: to.query, replace: true });
    } else {
      next({ path: to, replace: true });
    }

    return;
  }

  if (needsAuth) {
    next({ path: `/${to.params.meetingCode}/login`, replace: true });
    return;
  }

  if (needsRefresh) {
    router.push({ path: `/${to.params.meetingCode}/refresh`, query: to.query, replace: true });
    return;
  }

  //Redirect for claim only
  const claimOnly = to.matched?.some(record => record.meta.claimOnly);
  if ((requiresAuth || requiresAttending) && store.state.claimOnly && !claimOnly) {
    router.push({ path: `/${to.params.meetingCode}/claim` }).catch(() => { });
    return;
  }

  //Redirect for on-demand only
  const isScheduleRoute = to.matched?.some(record => record.name === "Schedule");
  if ((requiresAuth || requiresAttending) && store.state.onDemandOnly && isScheduleRoute) {
    router.push({ path: `/${to.params.meetingCode}/on-demand` }).catch(() => { });
    return;
  }

  //Redirect for challenge only
  if ((requiresAuth || requiresAttending) && store.state.challengeOnly && isScheduleRoute) {
    router.push({ path: `/${to.params.meetingCode}/challenge` }).catch(() => { });
    return;
  }

  //Redirect for materials only
  if ((requiresAuth || requiresAttending) && store.state.materialsOnly && isScheduleRoute) {
    router.push({ path: `/${to.params.meetingCode}/materials` }).catch(() => { });
    return;
  }

  // Redirect for archived meeting
  if ((requiresAuth || requiresAttending) && store.state.archived && isScheduleRoute) {
    router.push({ path: `/${to.params.meetingCode}/event-archived` }).catch(() => { });
    return;
  }

  //Store scroll position when doing schedule to schedule navigation
  if (to?.fullPath?.includes("schedule") && from?.fullPath?.includes("schedule")) {
    scheduleScrollPositionY = window.pageYOffset || document.documentElement.scrollTop;
  }

  next();

});

router.afterEach((to, from) => {
  //Restore scroll position when doing schedule to schedule navigation
  if (to?.fullPath?.includes("schedule") && from?.fullPath?.includes("schedule")) {
    if (scheduleScrollPositionY) {
      Vue.nextTick(() => {
        window.scrollTo(0, scheduleScrollPositionY);
      });
    }
  }
});

const trackActivity = (to) => {
  if (!store.state.authToken) return;
  store.dispatch("trackLoginActivity", { state: store.state, path: to.path }).catch();
}

export default router;